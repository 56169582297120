import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertCrane} from 'api/CranesApi';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import CraneConfigurationForm from './config/CraneConfigurationForm';

export const DEFAULT_INSTANCE = {
    id: '',
    crane_name: '',
    crane_comm_type: '',
    job_number: '',
    serial_number: '',
    crane_capacity: '',
    capacity_unit: '',
    active: ['1'],
};
export const VALIDATION_SCHEMA = Yup.object().shape({
    crane_name: Yup.string().required('Crane name is required'),
    crane_comm_type: Yup.string().required('Communication type is required'),
    job_number: Yup.string().required('Job number is required'),
    crane_capacity: Yup.number().required('Capacity is required'),
    capacity_unit: Yup.string().required('Unit is required'),
});

export default function CraneDetails({onCloseClick}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const isEditable = isSystemAdmin();

    useEffect(() => {
        isMounted.current = true;
        setPageTitle('New crane');
        return () => isMounted.current = false;
    }, []);

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {...values};
        payload.crane_comm_type = parseInt(payload.crane_comm_type);
        payload.active = Array.isArray(payload.active) && payload.active.length ? parseInt(payload.active[0]) === 1 : false;
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertCrane(payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Crane has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          initialValues={{...DEFAULT_INSTANCE}}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <CraneConfigurationForm
                  {...props}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseClick={onCloseClick}
                />
            )}
        </Formik>
    );
}
